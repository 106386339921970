import { createContext, useContext, useState, useEffect } from "react";
import roleDetails from "../api/user";
import baseUrl from "../config/baseUrl";
import { AuthContext } from "../context/Auth/AuthContext";

const RolesContext = createContext();

function RolesProvider({ children }) {
  const [roles, setRoles] = useState(() => {
    const savedRole = localStorage.getItem("role_id");
    return savedRole ? savedRole : null;
  });
  const [permissions, setPermissions] = useState([]);
  const { token } = useContext(AuthContext);

  const getPermissions = async () => {
    if (!roles) {
      console.error("No role selected");
      return;
    }
    try {
      const url = `${baseUrl}/api/get-role-details?roleId=${roles}`;
      const response = await roleDetails({ token, api: url });

      if (response.code !== 200) {
        throw new Error("Network response was not ok");
      }

      setPermissions(response?.data[0]?.permission_name.split(",") || []);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Trigger permission fetching when roles change
  useEffect(() => {
    if (roles) {
      getPermissions();
    }
  }, [roles]);

  return (
    <RolesContext.Provider
      value={{ roles, setRoles, getPermissions, permissions }}
    >
      {children}
    </RolesContext.Provider>
  );
}

const useRoleContext = function () {
  const context = useContext(RolesContext);
  if (context === undefined) {
    throw new Error("Context used outside of the provider");
  }
  return context;
};

export { RolesProvider, useRoleContext };
