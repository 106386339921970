import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addRole from "../../api/addRole";
import addRoleImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";

const addRoleFormSchema = yup.object().shape({
  name: yup.string().required("Role Name is a required field"),
  permissions: yup
    .array()
    .min(1, "Please select at least one permission")
    .required("Please select permissions"),
  status: yup.string().required("Status is required field"),
});

const AddRole = ({ onAddRoleButtonClick, setRoleAddedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch, // Add watch here
  } = useForm({
    resolver: yupResolver(addRoleFormSchema),
    defaultValues: {
      permissions: [], // Initialize permissions as an empty array
    },
  });

  const permissions = watch("permissions"); // Watch permissions array

  const permissionCategories = [
    {
      name: "All Documents",
      children: [
        { name: "View Documents", value: "view_all_documents" },
        { name: "Create Document", value: "create_all_documents" },
        { name: "Edit Document", value: "edit_all_documents" },
        { name: "Delete Document", value: "delete_all_documents" },
        { name: "Share Document", value: "share_all_documents" },
        { name: "Download Document", value: "download_all_documents" },
        { name: "Edit Pdf", value: "edit_pdf_documents" },
        { name: "Link Document", value: "link_documents" },
        { name: "Document Approval", value: "document_approval" },
        { name: "Client Edit", value: "client_edit" },
      ],
    },
    {
      name: "Clients",
      children: [
        { name: "View Clients", value: "view_clients" },
        { name: "Create Client", value: "create_clients" },
        { name: "Edit Client", value: "edit_clients" },
        { name: "Delete Client", value: "delete_clients" },
        { name: "View Requested Documents", value: "view_requested_documents" },
        {
          name: "Approve Requested Documents",
          value: "approve_requested_documents",
        },
        {
          name: "Reject Requested Documents",
          value: "reject_requested_documents",
        },
        {
          name: "Download Requested Documents",
          value: "download_requested_documents",
        },
        {
          name: "Delete Requested Documents",
          value: "delete_requested_documents",
        },
        { name: "View Beneficiaries", value: "view_beneficiaries" },
        { name: "Create Beneficiary", value: "create_beneficiaries" },
        { name: "Edit Beneficiary", value: "edit_beneficiaries" },
        { name: "Delete Beneficiary", value: "delete_beneficiaries" },
      ],
    },
    {
      name: "Mailroom",
      children: [
        { name: "All Assigned", value: "view_all_assigned" },
        { name: "All Unassigned", value: "view_all_unassigned" },
        { name: "My Assigned", value: "view_my_assigned" },
        { name: "Incoming Emails", value: "view_incoming_emails" },
        { name: "Incoming Faxes", value: "view_incoming_faxes" },
        { name: "Scanned Files", value: "view_scanned_files" },
      ],
    },
    {
      name: "Users",
      children: [
        { name: "View Users", value: "view_users" },
        { name: "Create User", value: "create_users" },
        { name: "Edit User", value: "edit_users" },
        { name: "Delete User", value: "delete_users" },
      ],
    },
    {
      name: "Document Requests",
      children: [
        { name: "View Document Requests", value: "view_document_requests" },
        { name: "Create Document Request", value: "create_document_requests" },
        { name: "Edit Document Request", value: "edit_document_requests" },
        { name: "Delete Document Request", value: "delete_document_requests" },
      ],
    },
    {
      name: "Field Settings",
      children: [
        { name: "View Field Settings", value: "view_field_settings" },
        { name: "Create Field Setting", value: "create_field_settings" },
        { name: "Edit Field Setting", value: "edit_field_settings" },
        { name: "Delete Field Setting", value: "delete_field_settings" },
      ],
    },
    {
      name: "Permanent Delete",
      children: [
        { name: "View Delete Documents", value: "view_trash_documents" },
        { name: "View Delete Users", value: "view_trash_users" },
        { name: "Delete Documents", value: "delete_documents_permanently" },
        { name: "Delete Users", value: "delete_users_permanently" },
      ],
    },
  ];

  const handleCategoryChange = (category, isChecked) => {
    if (isChecked) {
      const newPermissions = [
        ...permissions,
        ...category.children.map((child) => child.value),
      ];
      setValue("permissions", [...new Set(newPermissions)]);
    } else {
      const newPermissions = permissions.filter(
        (permission) =>
          !category.children.some((child) => child.value === permission)
      );
      setValue("permissions", newPermissions);
    }
  };

  const handlePermissionChange = (permission) => {
    const isChecked = permissions.includes(permission.value);

    if (isChecked) {
      const newPermissions = permissions.filter((p) => p !== permission.value);
      setValue("permissions", newPermissions);
    } else {
      const newPermissions = [...permissions, permission.value];
      setValue("permissions", [...new Set(newPermissions)]);
    }
  };

  const onAddRoleFormSubmit = async (addRoleFormData) => {
    setLoading(true);
    try {
      const addRoleFormResponse = await addRole({
        token,
        name: addRoleFormData.name,
        permissions: addRoleFormData.permissions,
        status: addRoleFormData.status,
      });

      if (addRoleFormResponse?.code === 200) {
        toast.success("Role is added successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onAddRoleButtonClick();
        setRoleAddedToTrue();
      } else {
        toast.error("Error while adding role", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("An unexpected error occurred", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img src={addRoleImg} height="100px" width="120px" alt="Add Role" />
            <div className="add-document-title">Add New Role</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddRoleButtonClick}
          />
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="name"
                label="Enter Role Name"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">{errors["name"]?.message}</span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Controller
                  control={control}
                  name="status"
                  defaultValue="active"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="status"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Status"
                      {...register("status")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["status"]?.message}</span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div
              className="add-document-inputs-with-error-message"
              style={{ width: "100%" }}
            >
              <div className="permission-categories">
                {permissionCategories.map((category) => (
                  <div key={category.name} className="category">
                    <div className="category-title">
                      <input
                        type="checkbox"
                        id={category.name}
                        onChange={(e) =>
                          handleCategoryChange(category, e.target.checked)
                        }
                        checked={category.children.every((child) =>
                          permissions.includes(child.value)
                        )}
                      />
                      <strong>
                        <label
                          htmlFor={category.name}
                          style={{ marginLeft: "3px" }}
                        >
                          {category.name}
                        </label>
                      </strong>
                    </div>
                    <div className="category-permissions">
                      {category.children.map((permission) => (
                        <div
                          key={permission.value}
                          className="permission"
                          style={{
                            display: "inline-block",
                            margin: "5px 10px",
                            width: "30%",
                          }}
                        >
                          <input
                            type="checkbox"
                            id={permission.value}
                            onChange={() => handlePermissionChange(permission)}
                            checked={permissions.includes(permission.value)}
                          />
                          <label
                            htmlFor={permission.value}
                            style={{ marginLeft: "3px" }}
                          >
                            {permission.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <span className="error-message">
                {errors["permissions"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Role"
              style={{
                width: "150px",
                height: "35px",
                color: "#fff",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddRoleFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRole;
