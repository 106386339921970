import baseUrl from "../config/baseUrl";

const editUser = async ({
  token,
  user_id,
  id,
  first_name,
  last_name,
  is_admin,
  status,
  user_role
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("id", id);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("is_admin", is_admin);
  formData.append("status", status);
  try {
    const response = await fetch(`${baseUrl}/api/update-user-data?id=${id}&first_name=${first_name}&last_name=${last_name}&is_admin=${is_admin}&status=${status}&role_id=${user_role}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editUser;
