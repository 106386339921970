import React, { useState, useEffect, useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CaretSortIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn-components/ui/select";

import { Button } from "../../../shadcn-components/ui/button";
import { Checkbox } from "../../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../shadcn-components/ui/table";
import TableSkeleton from "../../common/TableSkeleton";
import PaginationSection from "../../common/PaginationSection";
import DataToolbar from "../../common/DataToolbar";

import { AuthContext } from "../../../context/Auth/AuthContext";
import baseUrl from "../../../config/baseUrl";
import "../../../styles/Clients.css";
import "../../../styles/DocumentTable.css";
import { fileIcon } from "../../common/FileIcons";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../shadcn-components/ui/pagination";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shadcn-components/ui/tooltip";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SelectBox from "@mui/material/Select";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import requestedDocumentList from "../../../api/requestedDocumentList";
import editDocument from "../../../api/editDocument";
import Loading from "../../material-ui/Loading";
import PdfEditor from "../../pdf-viewer/PdfEditor";
import ButtonUI from "../../material-ui/ButtonUI";
import "../../../styles/CaseDocuments.css";
import pdfBase64Data from "../../../api/pdfBase64Data";
import getAllFieldTypeList from "../../../api/getAllFieldTypeList";
import CopyLinkPopup from "../../all-case-list/case-table/CopyLinkPopup";
import DocumentViewer from "../../all-case-list/case-table/DocumentViewer";
import DeleteDocumentPopup from "../../all-case-list/case-table/DeleteDocumentPopup";
import EditDocumentPopup from "../../all-case-list/case-table/EditDocumentPopup";
import viewDocumentLink from "../../../api/viewDocumentLink";
import { toast } from "react-toastify";
import getAllAssignUsers from "../../../api/getAllAssignUsers";
import AssignUserPopup from "../../email-case-list/case-list-table/AssignUserPopup";
import BulkDeletePopup from "../../document-table/BulkDeletePopup";
import LinkToRequest from "../../document-table/LinkToRequest";
import ApprovePDFDocumentPopup from "../../client-table/client-documents/ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "../../client-table/client-documents/RejectPDFDocumentPopup";
import { format } from "date-fns";
import editClient from "../../../api/editClient";
import clientsData from "../../../api/clients";
import getClientRefNo from "../../../api/getClientRefNo";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { BiLinkAlt } from "react-icons/bi";
import InputUI from "../../material-ui/InputUI";
import useDebounceSearch from "../../../hooks/useDebounceSearch";
import getClientName from "../../../api/getClientName";
import { useDebounce } from "use-debounce";
import { useRoleContext } from "../../../context/RolesContext";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

/*** Component for listing all the mailroom documents inside the datewise folder for all mailroom sub sections ***/
const AllDocumentsList = ({
  caseList,
  sourceDetails,
  createdAt,
  categoryName,
  folderName,
  assigned,
  assigned_user_id,
  mainCaseList,
}) => {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
  const [loading, setLoading] = useState(true);
  const [docsCount, setDocsCount] = useState(0);
  const [reqStatus, setReqStatus] = useState("");
  const [requestId, setRequestId] = useState("");
  const [userList, setUserList] = useState("");
  const [documentUpdated, setDocumentUpdated] = useState(0);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [documentViewer, setDocumentViewer] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [pdfBase64, setPdfBase64] = useState("");
  const [pdfEditor, setPdfEditor] = useState(false);
  const [docFileType, setDocFileType] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [contactId, setContactId] = useState("");
  const [clientRefNo, setClientRefNo] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [description, setDescription] = useState("");

  const [bulkAssign, setBulkAssign] = useState(false);
  const [bulkAssignIds, setBulkAssignIds] = useState(null);
  const [bulkDeleteDocument, setBulkDeleteDocument] = useState(false);
  const [documentResponse, setDocumentResponse] = useState([]);
  const [bulkDeleteDocumentIds, setBulkDeleteDocumentIds] = useState();
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [showFilterButton, setShowFilterButton] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [clientNames, setClientNames] = useState([]);

  const [requestOptions, setRequestOptions] = useState([]);
  const [linkRequest, setLinkRequest] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);
  const [showLinkButton, setShowLinkButton] = useState(false);
  const [assignClient, setAssignClient] = useState(false);
  const [existedContactId, setExistedContactId] = useState("");
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [searchClientNotFound, setSearchClientNotFound] = useState(false);

  const { token, is_admin, user_id } = useContext(AuthContext);
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }

    return permissions.includes(permission);
  };

  const canCopy = hasPermission("copy_all_documents");
  const canEdit = hasPermission("edit_all_documents");
  const canView = hasPermission("view_all_documents");
  const canDelete = hasPermission("delete_all_documents");

  const shouldShowActionColumn = canCopy || canEdit || canView || canDelete;

  const caseListUrls = {
    email: "/api/get-email-filter-name",
    scanned: "/api/get-scan-filter-name",
    fax: "/api/get-fax-filter-name",
  };

  const getClientList = useCallback(
    async (searchTerm) => {
      if (searchTerm) {
        const url = `${baseUrl}/api/get-all-client-data?isAdmin=${is_admin}&userId=${user_id}&client_name=${searchTerm}`;
        const res = await clientsData({ token, api: url });
        if (res.code === 200) {
          setClientNames(res?.data[0]?.clients);
        } else {
          setSearchClientNotFound(true);
        }
      } else {
        setClientNames([]);
      }
    },
    [is_admin, user_id, token]
  );
  const [searchTerm, handleClientNameSearch] = useDebounceSearch(
    "",
    500,
    getClientList
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  let sourceDetailsQuery =
    sourceDetails !== "" && sourceDetails !== null
      ? `&document_source_details=${sourceDetails}`
      : "";

  let createdAtQuery =
    createdAt && createdAt !== "" && createdAt !== null
      ? `&created_at=${format(new Date(createdAt), "yyyy-MM-dd")}`
      : "";

  let assignedQuery = "";
  if (
    assigned &&
    assigned_user_id &&
    assigned_user_id !== "" &&
    assigned_user_id !== null
  ) {
    assignedQuery = `&assign_user_id=${assigned_user_id}`;
  }

  const setDocumentUpdatedToTrue = () => {
    setDocumentUpdated((count) => count + 1);
  };

  const setDocumentDeletedToTrue = () => {
    setDocumentDeleted((count) => count + 1);
  };

  const changeActiveTab = () => {
    setDocumentViewer(false);
    setPdfEditor(false);
  };

  const getClientNameDetails = async (clientId) => {
    const clientNameResponse = await getClientName({
      contact_id: clientId,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data[0]?.name);
  };

  const getRequestNameDetails = async (reqId) => {
    const url = `${baseUrl}/api/get-request-name?document_request_id=${reqId}`;
    const res = await clientsData({ token, api: url });
    setSelectedRequestLabel(res.data[0].document_name);
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllAssignUsers({
        token,
      });

      setUserList(response?.data);
    } catch (error) {
      return error;
    }
  };

  const changeDocumentViewerState = async (
    caseID,
    contactId,
    name,
    category,
    subCategory,
    desc,
    id,
    type
  ) => {
    setLoading(true);
    const response = await viewDocumentLink({
      token: token,
      docId: id,
    });

    const data = await response.data[0];
    const link = data?.pdflink;
    setRequestId(data?.document_request_id);
    setReqStatus(data?.document_status);
    if (contactId) {
      setContactId(contactId);
      setExistedContactId(contactId);
      getClientNameDetails(contactId);
    } else {
      setContactId("");
    }
    setValue("addClientName", contactId);
    fetchRequestList(contactId);
    // if (caseID) {
    // 	setClientRefNo(caseID);
    // } else {
    // 	setClientRefNo("");
    // }

    if (data?.document_request_id) {
      getRequestNameDetails(data?.document_request_id);
    }

    setValue("caseId", caseID);
    setValue("name", name);
    setSubCategory(subCategory);
    setValue("subcategory", subCategory);
    setValue("category", category);
    setDescription(desc);
    setDocumentId(id);
    setPdfLink(link);
    setDocFileType(type);
    if (type == "pdf") {
      const responseBase64Data = await pdfBase64Data(token, id, "documents");
      const base64Data = await responseBase64Data.data;
      setPdfBase64(base64Data);
    }
    setPdfViewerTitle(name);
    setDocumentViewer(true);
    setLoading(false);
  };

  const documentResponseFunc = async () => {
    setLoading(true);
    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });
    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      document_source_type: caseList,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get-all-document-related-mailroom?${queryString}${sourceDetailsQuery}${createdAtQuery}${assignedQuery}`;
    console.log(url);

    const response = await requestedDocumentList({ token, api: url });
    if (response.code == 200) {
      setDocumentResponse(response?.data);
      setTotalPages(Math.ceil(response?.document_count / pageSize));
    } else {
      setDocumentResponse([]);
    }
    setLoading(false);
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOption(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    fetchSubCategory();
  }, []);

  const handleLinkRequest = (e) => {
    fetchRequestList(contactId);
    setLinkRequestOpen(true);
  };

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);

    const ids = selectedRowsData.map((row) => row.documentID).join(",");

    if (actionType === "delete") {
      setBulkDeleteDocument(true);
      setBulkDeleteDocumentIds(ids);
    } else {
      setBulkAssign(true);
      setBulkAssignIds(ids);
    }
  };

  const handleClientNameChange = async (contactId) => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: contactId,
      token: token,
    });
    //setClientRefNo(clientRefNoResponse?.data[0]?.client_reference_number);
    setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
    setContactId(contactId);
  };

  const fetchRequestList = async (selectedClient) => {
    if (selectedClient) {
      setLinkRequest(false);
      const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data?.code === 200) {
          setRequestOptions(data.data[0]?.alldocumentcases);
        } else {
          setRequestOptions("");
        }
      } catch (error) {
        return error;
      }
    } else {
      setLinkRequest(true);
    }
  };

  useEffect(() => {
    documentResponseFunc();
  }, [
    bulkDeleteDocument,
    documentDeleted,
    documentUpdated,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    pageSize,
    sorting,
    documentViewer,
  ]);

  useEffect(() => {
    fetchSubCategory();
    //getClientList();
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchRequestList(contactId);
  }, [assignClient]);

  useEffect(() => {
    contactId && !linkRequest && requestOptions?.length > 0 && !reqStatus
      ? setShowLinkButton(true)
      : setShowLinkButton(false);
  }, [contactId, requestOptions, reqStatus]);

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    // await editClient({
    // 	contact_id: contactId,
    // 	token: token,
    // 	user_id: user_id,
    // 	client_reference_number: clientRefNo,
    // });

    const editDocumentFormResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: documentId,
      document_desc: description,
      document_name: editDocumentFormData.name,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    });

    if (editDocumentFormResponse.code === 200) {
      setPdfViewerTitle(editDocumentFormData.name);
      setAssignClient(true);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let columns;
  if (assigned == "TRUE") {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                    Delete
                  </DropdownMenuItem>
                  <DropdownMenuItem onSelect={() => handleBulkAction("assign")}>
                    Assign
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              setSelectAllChecked(true);

              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "client_name",
        displayName: "Client Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("client_name")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_source_details",
        displayName: `${caseList} Id`,
        filterable: true,

        header: ({ column }) => (
          <Button
            className="capitalize p-0"
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {caseList} Id
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="text-left">
            {row.getValue("document_source_details")}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },

      {
        accessorKey: "document_type",
        displayName: "Type",
        filterable: true,
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
              className="p-0"
            >
              Type
              <CaretSortIcon className="ml-2 h-6" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="w-full flex items-center justify-center">
            {fileIcon[row.getValue("document_type")]}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_name",
        displayName: "File Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            File Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const document_name = row.getValue("document_name");

          return (
            <div
              className={`capitalize text-left    ${
                canView
                  ? "text-blue-500 cursor-pointer  underline"
                  : "text-gray-500 cursor-not-allowed"
              }`}
              onClick={() => {
                if (!canView) return;
                changeDocumentViewerState(
                  row.original.client_reference_number,
                  row.original.contact_id,
                  row.original.document_name,
                  row.original.category,
                  row.original.document_sub_category,
                  row.original.document_desc,
                  row.original.documentID,
                  row.original.document_type
                );
              }}
            >
              {document_name}
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "assign_user",
        displayName: "Assigned User",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Assigned User
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const assign_user = row.getValue("assign_user")
            ? row.getValue("assign_user")
            : "Unassigned";

          return <div className="capitalize">{assign_user}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: "Uploaded On",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Uploaded On
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const uploaded = row.getValue("created_at");

          return <div className="capitalize">{uploaded}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "updated_at",
        displayName: "Modified At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified At
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("updated_at")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_count",
        displayName: "Actions",
        header: ({ column }) => {
          if (!shouldShowActionColumn) {
            return null;
          }
          return (
            <Button variant="ghost" className="p-0 !cursor-default">
              Actions
            </Button>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex">
              {canEdit && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <EditDocumentPopup
                          tableValues={row.original}
                          subCategoryOption={subCategoryOption}
                          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canCopy && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <CopyLinkPopup tableValues={row.original} />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Copy</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canView && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DocumentViewer
                          tableValues={row.original}
                          changeDocumentViewerState={changeDocumentViewerState}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>View</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canDelete && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DeleteDocumentPopup
                          tableValues={row.original}
                          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Delete</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableColumnFilter: false,
      },
    ];
  } else {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <div className="flex items-center">
            <Checkbox
              checked={
                table.getIsAllPageRowsSelected() ||
                (table.getIsSomePageRowsSelected() && "indeterminate")
              }
              onCheckedChange={(value) =>
                table.toggleAllPageRowsSelected(!!value)
              }
              aria-label="Select all"
            />
            {(table.getIsSomePageRowsSelected() ||
              table.getIsAllPageRowsSelected()) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="p-0 !border-none !shadow-none bg-transparent"
                  >
                    <ChevronDownIcon className="" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                    Delete
                  </DropdownMenuItem>
                  <DropdownMenuItem onSelect={() => handleBulkAction("assign")}>
                    Assign
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              setSelectAllChecked(true);
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "client_name",
        displayName: "Client Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Client Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("client_name")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_source_details",
        displayName: `${caseList} Id`,
        filterable: true,
        header: ({ column }) => (
          <Button
            className="capitalize p-0"
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {caseList} Id
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="text-left">
            {row.getValue("document_source_details")}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },

      {
        accessorKey: "document_type",
        filterable: true,
        displayName: "Type",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === "asc")
              }
              className="p-0"
            >
              Type
              <CaretSortIcon className="ml-2 h-6" />
            </Button>
          );
        },
        cell: ({ row }) => (
          <div className="w-full flex items-center ">
            {fileIcon[row.getValue("document_type")]}
          </div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_name",
        displayName: "File Name",
        filterable: true,
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            File Name
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const document_name = row.getValue("document_name");

          return (
            <div
              className={`capitalize text-left     ${
                canView
                  ? "text-blue-500 underline cursor-pointer"
                  : "cursor-not-allowed text-gray-500"
              }`}
              onClick={() => {
                if (!canView) return;
                changeDocumentViewerState(
                  row.original.client_reference_number,
                  row.original.contact_id,
                  row.original.document_name,
                  row.original.category,
                  row.original.document_sub_category,
                  row.original.document_desc,
                  row.original.documentID,
                  row.original.document_type
                );
              }}
            >
              {document_name}
            </div>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "modified_user",
        displayName: "Modified By",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified By
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const modified_by = row.getValue("modified_user");

          return <div className="capitalize">{modified_by}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "created_at",
        displayName: " Uploaded On",

        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Uploaded On
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => {
          const uploaded = row.getValue("created_at");

          return <div className="capitalize">{uploaded}</div>;
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "updated_at",
        displayName: "Modified At",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Modified At
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => (
          <div className="capitalize">{row.getValue("updated_at")}</div>
        ),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "document_count",
        displayName: "Actions",
        header: ({ column }) => {
          if (!shouldShowActionColumn) {
            return null;
          }
          return (
            <Button variant="ghost" className="p-0 !cursor-default">
              Actions
            </Button>
          );
        },
        cell: ({ row }) => {
          return (
            <div className="flex">
              {canEdit && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <EditDocumentPopup
                          tableValues={row.original}
                          subCategoryOption={subCategoryOption}
                          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Edit</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canCopy && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <CopyLinkPopup tableValues={row.original} />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Copy</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canView && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DocumentViewer
                          tableValues={row.original}
                          changeDocumentViewerState={changeDocumentViewerState}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>View</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}

              {canDelete && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <span>
                        <DeleteDocumentPopup
                          tableValues={row.original}
                          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                        />
                      </span>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-600">
                      <p>Delete</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          );
        },
        enableSorting: false,
        enableHiding: false,
        enableColumnFilter: false,
      },
    ];
  }

  const table = useReactTable({
    data: documentResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setReqStatus}
          setDocumentViewer={setDocumentViewer}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
        />
      )}
      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={requestId}
          setReqStatus={setReqStatus}
          setDocumentViewer={setDocumentViewer}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
        />
      )}
      {linkRequestOpen && (
        <LinkToRequest
          documentId={documentId}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setReqStatus}
          setRequestId={setRequestId}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
        />
      )}

      {bulkDeleteDocument ? (
        <BulkDeletePopup
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
          Docbulkid={bulkDeleteDocumentIds}
          bulkDeleteDocument={bulkDeleteDocument}
          setBulkDeleteDocument={setBulkDeleteDocument}
          setRowSelection={setRowSelection}
        />
      ) : null}
      {bulkAssign ? (
        <AssignUserPopup
          selectedRows={table
            ?.getFilteredSelectedRowModel()
            ?.rows.map((row) => row.original)}
          userList={userList}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          bulkAssign={bulkAssign}
          setBulkAssign={setBulkAssign}
          setRowSelection={setRowSelection}
          bulkAssignIds={bulkAssignIds}
        />
      ) : null}
      {!documentViewer ? (
        <>
          <div className="document__table">
            <div className="w-full bg-white px-2">
              <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                <Input
                  placeholder="Search..."
                  value={globalFilter}
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                  className="max-w-sm"
                />
                <div className="flex gap-2">
                  <Button
                    variant="outline"
                    className=""
                    onClick={() => setShowFilterButton(!showFilterButton)}
                  >
                    <MixerHorizontalIcon className="mr-1" />
                    Filter
                  </Button>

                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" className="ml-auto">
                        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter((column) => column.getCanHide())
                        .map((column) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={column.id}
                              className="capitalize"
                              checked={column.getIsVisible()}
                              onCheckedChange={(value) =>
                                column.toggleVisibility(!!value)
                              }
                            >
                              {column.columnDef.displayName}
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              {showFilterButton && (
                <DataToolbar
                  table={table}
                  apiUrl={caseListUrls[caseList.toLowerCase()]}
                />
              )}
              <div className="rounded-md border">
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <Table>
                    <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            {loading ? <TableSkeleton /> : "no records found"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                <div className="flex items-center gap-5">
                  <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of{" "}
                    {table.getFilteredRowModel().rows.length} row(s) selected.
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                      value={`${table.getState().pageSize}`}
                      onValueChange={(value) => {
                        setPageSize(Number(value));
                        setCurrentPage(1);
                      }}
                    >
                      <SelectTrigger className="w-auto">
                        <SelectValue placeholder={table.getState().pageSize} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {[10, 20, 30, 40, 50].map((pageSize) => (
                            <SelectItem key={pageSize} value={`${pageSize}`}>
                              {pageSize}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>

                <div className="space-x-2">
                  <PaginationSection
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!loading ? (
            !pdfEditor ? (
              <div className="all-document-pdf-viewer">
                <div className="all-document-pdf-viewer-header">
                  <div className="all-document-pdf-viewer-title capitalize">
                    {pdfViewerTitle}
                    {selectedRequestLabel
                      ? " - " + selectedRequestLabel + " (Linked Request)"
                      : null}
                  </div>
                  <div className="all-document-pdf-viewer-icons">
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<TbCircleCheck />}
                      style={{
                        backgroundColor:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "#28a745"
                            : "#d8d8e4df",

                        color:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "white"
                            : "rgb(18 215 24)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={
                        reqStatus?.toLowerCase() === "uploaded" ? false : true
                      }
                      onClickFunc={handleApproveDocument}
                    />
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<TiDeleteOutline />}
                      style={{
                        backgroundColor:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "#dc3545"
                            : "#d8d8e4df",

                        color:
                          reqStatus?.toLowerCase() === "uploaded"
                            ? "white"
                            : "rgb(215 18 18)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={
                        reqStatus?.toLowerCase() === "uploaded" ? false : true
                      }
                      onClickFunc={handleDisapproveDocument}
                    />
                    <ButtonUI
                      formButton={false}
                      variant="outlined"
                      buttonText={<BiLinkAlt />}
                      style={{
                        backgroundColor: showLinkButton
                          ? "#17a2b8"
                          : "#d8d8e4df",
                        color: showLinkButton ? "white" : "rgb(18 215 24)",
                        marginRight: "5px",
                        marginBottom: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                      disabled={showLinkButton ? false : true}
                      onClickFunc={handleLinkRequest}
                    />

                    {/* {docFileType === "pdf" ? (
											<div
												className="all-document-pdf-viewer-icon"
												onClick={() => setPdfEditor(true)}
											>
												<TbEdit />
											</div>
										) : null} */}
                    <div
                      className="all-document-pdf-viewer-icon"
                      onClick={() => setDocumentViewer(false)}
                    >
                      <RiCloseCircleFill />
                    </div>
                  </div>
                </div>
                <div className="document-viewer-with-from">
                  <div className="iframe-wrapper">
                    {docFileType === "pdf" && pdfLink ? (
                      <iframe
                        title={pdfViewerTitle}
                        src={pdfLink}
                        width="100%"
                        height="590"
                      ></iframe>
                    ) : (
                      <img
                        src={pdfLink}
                        width="100%"
                        height="590"
                        alt={pdfViewerTitle}
                      />
                    )}
                  </div>
                  <div className="document-viewer-form">
                    <div className="field_wrapper">
                      <div className="field">
                        <Controller
                          control={control}
                          name="addClientName"
                          render={({
                            field: { onChange, value, ...field },
                          }) => (
                            <Autocomplete
                              className="clientNameAuto"
                              name="addClientName"
                              noOptionsText={
                                !searchClientNotFound
                                  ? "Please enter the client name"
                                  : "No clients found"
                              }
                              options={clientNames || []}
                              getOptionLabel={(option) => option.name}
                              clearOnBlur={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    !clientNameLabel
                                      ? "Client Name"
                                      : clientNameLabel
                                  }
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  onChange={(event) => {
                                    const searchTerm = event.target.value;
                                    handleClientNameSearch(searchTerm);
                                  }}
                                  disabled={
                                    existedContactId &&
                                    existedContactId !== "null"
                                  }
                                />
                              )}
                              filterOptions={(options, state) => {
                                return options.filter((option) =>
                                  option.name
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                                );
                              }}
                              renderOption={(props, option) => (
                                <li {...props} key={option.contact_id}>
                                  {option.name}
                                </li>
                              )}
                              {...field}
                              value={
                                clientNames?.find(
                                  (clientName) =>
                                    clientName.contact_id === value
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                if (
                                  newValue === null ||
                                  newValue === undefined
                                ) {
                                  onChange(null);
                                  setValue("caseId", "");
                                  setContactId("");
                                } else {
                                  const selectedClient = clientNames?.find(
                                    (clientName) =>
                                      clientName.name === newValue?.name
                                  );
                                  if (selectedClient) {
                                    onChange(selectedClient.contact_id);
                                    handleClientNameChange(
                                      selectedClient.contact_id
                                    );
                                  }
                                }
                              }}
                              classes={{ paper: "autocomplete-paper" }}
                            />
                          )}
                        />
                        <span className="error-message">
                          {errors["addClientName"]?.message}
                        </span>
                      </div>
                    </div>
                    <div className="field_wrapper">
                      <div className="field">
                        <Controller
                          name="caseId"
                          //defaultValue={clientRefNo}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputUI
                              name="caseId"
                              label="Client Reference Number"
                              disabled
                              type="text"
                              value={value ? value : ""}
                              setValue={onChange}
                              variant="outlined"
                              register={register}
                              cssStyles={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="field_wrapper">
                      <div className="field">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputUI
                              name="name"
                              label="Document Name"
                              type="text"
                              value={value ? value : ""}
                              setValue={onChange}
                              variant="outlined"
                              register={register}
                              cssStyles={{
                                margin: "20px 0px",
                                width: "100%",
                              }}
                            />
                          )}
                        />
                        <span className="error-message">
                          {errors["name"]?.message}
                        </span>
                      </div>
                    </div>
                    <div className="field_wrapper">
                      <div className="field">
                        <Controller
                          name="category"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputUI
                              name="category"
                              label="Category"
                              disabled
                              type="text"
                              value={value ? value : ""}
                              variant="outlined"
                              register={register}
                              cssStyles={{
                                width: "100%",
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className="field_wrapper"
                      style={{ marginTop: "15px", marginBottom: "5px" }}
                    >
                      <div className="field">
                        <FormControl fullWidth>
                          <InputLabel id="select-label">
                            Sub Category
                          </InputLabel>
                          <Controller
                            control={control}
                            name="subcategory"
                            defaultValue={subCategory || ""}
                            render={({ field: { onChange, value } }) => (
                              <SelectBox
                                name="subcategory"
                                labelId="select-label"
                                variant="outlined"
                                id="simple-select"
                                value={value || ""}
                                onChange={onChange}
                                label="Sub Category"
                                {...register("subcategory")}
                              >
                                {subCategoryOption?.map((category) => (
                                  <MenuItem
                                    key={category.field_setting_id}
                                    value={category.field_setting_id}
                                  >
                                    {category.fields_name}
                                  </MenuItem>
                                ))}
                              </SelectBox>
                            )}
                          />
                        </FormControl>
                        <span className="error-message">
                          {errors["subcategory"]?.message}
                        </span>
                      </div>
                    </div>
                    <div className="field_wrapper">
                      <div className="field">
                        <TextField
                          name="description"
                          label="Description"
                          multiline
                          minRows={3}
                          maxRows={3}
                          value={description || ""}
                          onChange={(e) => setDescription(e.target.value)}
                          variant="outlined"
                          style={{ marginTop: "15px", marginBottom: "15px" }}
                          fullWidth
                        />
                      </div>
                    </div>
                    <ButtonUI
                      variant="contained"
                      buttonText="Submit"
                      handleSubmit={handleSubmit}
                      onFormSubmit={onEditDocumentFormSubmit}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="all-document-pdf-editor">
                <div
                  onClick={() => setPdfEditor(false)}
                  className="all-document-pdf-editor-icon"
                  title="Back"
                >
                  <IoArrowBackCircleSharp />
                </div>
                <div>
                  <PdfEditor
                    pdfBase64={pdfBase64}
                    documentID={documentId}
                    documentTitle={pdfViewerTitle}
                    setEditor={setPdfEditor}
                  />
                </div>
              </div>
            )
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
};

export default AllDocumentsList;
