import baseUrl from "../config/baseUrl";

const addUser = async ({
  token,
  userId,
  first_name,
  last_name,
  user_email,
  is_admin,
  user_role
}) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("first_name", first_name);
  formData.append("last_name", last_name);
  formData.append("email", user_email);
  formData.append("is_admin", is_admin);
  formData.append("role_id", user_role);

  try {
    const response = await fetch(`${baseUrl}/api/add-user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addUser;
