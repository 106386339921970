import React, { useState, useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import baseUrl from "../../config/baseUrl";
import { AuthContext } from "../../context/Auth/AuthContext";
import { RiCloseCircleFill } from "react-icons/ri";

const EditDocumentForm = ({ register, control, errors, tableValues }) => {
  const [userRolesOptions, setUserRolesOptions] = useState([]);
  const [userType, setUserType] = useState("");
  const [tags, setTags] = useState([]);
  const { token } = useContext(AuthContext);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(tableValues?.is_admin);

  const userRolesLoad = async () => {
    const url = `${baseUrl}/api/get-user-roles`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setUserRolesOptions(data?.data);
      const roleNames = data?.data.map((item) => item.name);
      setTags(data?.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    userRolesLoad();
  }, []);

  const roleOptions = [
    {
      name: "Admin",
      value: 1,
    },
    {
      name: "Non Admin",
      value: 0,
    },
  ];

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  console.log(tags);

  const removeTags = (index) => {
    const filterdTags = tags.filter((tag, i) => i !== index);
    setTags(filterdTags);
  };

  useEffect(() => {
    if (tableValues?.role_id) {
      setSelectedRoles(tableValues.role_id.split(",")); // Convert string to array
    }
  }, [tableValues]);

  // Remove a role when "X" is clicked
  const removeRole = (roleId) => {
    setSelectedRoles((prev) => prev.filter((id) => id !== roleId));
  };

  console.log(tags);
  console.log(tableValues?.role_id);
  console.log(selectedRoles);

  const addRole = (roleId) => {
    if (!selectedRoles.includes(roleId)) {
      setSelectedRoles((prev) => [...prev, roleId]);
    }
  };

  console.log("isAdmin", isAdmin);
  console.log(tableValues?.is_admin === "0" && isAdmin === "0");

  return (
    <Grid className="">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="firstName"
                defaultValue={tableValues.first_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="firstName"
                    label="First Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["firstName"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="lastName"
                defaultValue={tableValues.last_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="lastName"
                    label="Last Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">{errors["lastName"]?.message}</span>
          </div>
        </div>
        {tableValues?.is_admin === "0" && isAdmin === "0" && (
          <>
            <div className="field_wrapper mb-5">
              <div className="field" style={{ height: "85px" }}>
                <FormControl fullWidth>
                  <InputLabel id="edit-doc-select-label">Roles</InputLabel>
                  <Controller
                    control={control}
                    name="userRole"
                    defaultValue={
                      tableValues.role_id ? tableValues.role_id.split(",") : []
                    }
                    render={({ field }) => {
                      const { value, onChange } = field;
                      const selectedRoles = value || [];

                      // Handle dropdown selection
                      const handleSelectChange = (event) => {
                        onChange(event.target.value); // Update form state
                      };

                      // Remove a tag (also deselects from dropdown)
                      const removeRole = (roleId) => {
                        const updatedRoles = selectedRoles.filter(
                          (id) => id !== roleId
                        );
                        onChange(updatedRoles); // Update form state
                      };

                      return (
                        <>
                          <Select
                            name="userRole"
                            labelId="select-label"
                            variant="outlined"
                            id="simple-select"
                            multiple
                            value={selectedRoles}
                            onChange={handleSelectChange}
                            label="Roles"
                          >
                            {userRolesOptions.map((option) => (
                              <MenuItem
                                key={option.role_id}
                                value={option.role_id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {/* Tags container */}
                          <div className="flex flex-wrap gap-2 p-2 bg-gray-100 rounded-b-lg rounded-none border border-gray-300">
                            {selectedRoles.map((roleId, index) => {
                              const role = userRolesOptions.find(
                                (r) => r.role_id === roleId
                              );
                              return (
                                <div
                                  key={index}
                                  className=" flex items-center gap-1 bg-blue-500 text-white px-3 py-1 rounded-full text-sm shadow-md"
                                >
                                  <span className="text">{role?.name}</span>
                                  <span
                                    className="close cursor-pointer hover:text-red-400 transition"
                                    onClick={() => removeRole(roleId)}
                                  >
                                    <RiCloseCircleFill className="w-4 h-4" />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      );
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </>
        )}

        <div className="field_wrapper">
          <div className="field" style={{ height: "85px" }}>
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                User Type
              </InputLabel>
              <Controller
                control={control}
                name="role"
                defaultValue={tableValues.is_admin}
                render={({ field }) => (
                  <Select
                    {...field} // Spread field to bind value and onChange correctly
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    onChange={(e) => {
                      setIsAdmin(e.target.value.toString()); // Update external state
                      field.onChange(e); // Update form state
                    }}
                    label="User Type"
                  >
                    {roleOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["role"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field" style={{ height: "85px" }}>
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status.toLowerCase()}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditDocumentForm;
