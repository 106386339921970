import React, { useState, useEffect, useContext } from "react";
import RequestIcon from "../../../images/mdi_invoice-new-outline.svg";
import UploadIcon from "../../../images/uil_file-upload-alt.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import requestedDocumentList from "../../../api/requestedDocumentList";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Loading from "../../material-ui/Loading";
import RequestedDocumentsTable from "./RequestedDocumentsTable";
import RequestDocumentsPopup from "./RequestDocumentsPopup";
import AddBeneficiaryPopup from "../client-documents/all-documents/AddBeneficiaryPopup";
import ApprovePDFDocumentPopup from "./ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "./RejectPDFDocumentPopup";
import UploadDocumentsPopup from "./all-documents/UploadDocumentsPopup";
import baseUrl from "../../../config/baseUrl";
import getAllFieldTypeList from "../../../api/getAllFieldTypeList";
import { BsFillCloudArrowUpFill, BsCloudArrowUp } from "react-icons/bs";
import DocumentTable from "./all-documents/uploaded/DocumentTable";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { Button } from "../../../shadcn-components/ui/button";
import BeneficiaryTable from "./all-documents/BeneficiaryTable";
import { FilePenLineIcon, FolderUp, UsersRound } from "lucide-react";
import ButtonUI from "../../material-ui/ButtonUI";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputUI from "../../material-ui/InputUI";
import SelectBox from "@mui/material/Select";
import getClientRefNo from "../../../api/getClientRefNo";
import { toast } from "react-toastify";
import editDocument from "../../../api/editDocument";
import clientsData from "../../../api/clients";
import { BiLinkAlt } from "react-icons/bi";
import LinkToRequest from "../../document-table/LinkToRequest";
import getClientName from "../../../api/getClientName";
import { useDebounce } from "use-debounce";
import OcrContainer from "../../ocr/OcrContainer";
import { useRoleContext } from "../../../context/RolesContext";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required field"),
  subcategory: yup
    .string()
    .nullable()
    .required("Sub Category is required field"),
});

/*** Component for listing all the document requests in table and for listing all uploaded documents in another table ***/
const RequestedDocumentsList = ({
  clientID,
  clientType,
  clientDocumentsType,
  selectedClientDocumentsType,
  changeSelectedClientDocumentsType,
  setShowClientIdSearch,
  showUploads,
  beneficiary,
  typeOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);

  const [bulkDeleteRequests, setBulkDeleteRequests] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  const [documentViewer, setDocumentViewer] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [name, setName] = useState("");
  const [docCaseID, setDocCaseID] = useState("");
  const [docReqID, setDocReqID] = useState("");
  const [reqStatus, setReqStatus] = useState("");
  const [description, setDescription] = useState("");
  const [sorting, setSorting] = useState([]);

  const [clientNames, setClientNames] = useState([]);
  const [requestOptions, setRequestOptions] = useState([]);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [linkRequest, setLinkRequest] = useState(false);
  const [selectedRequestLabel, setSelectedRequestLabel] = useState("");
  const [ocrData, setOcrData] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const getRequestNameDetails = async (reqId) => {
    const url = `${baseUrl}/api/get-request-name?document_request_id=${reqId}`;
    const res = await clientsData({ token, api: url });
    setSelectedRequestLabel(res.data[0].document_name);
  };

  const changeDocumentViewerState = (
    id,
    requestId,
    requestStatus,
    link,
    name,
    category,
    description,
    document_sub_category,
    document_type
  ) => {
    setSelectedRequestLabel("");
    setDocumentType(document_type);
    setPdfLink(link);
    setName(name);
    setDocCaseID(id);
    setDocReqID(requestId);
    if (requestId) {
      getRequestNameDetails(requestId);
    }
    setReqStatus(requestStatus);
    setDescription(description);
    setCategory(category);
    setSubCategory(document_sub_category);
    setPdfViewerTitle(name);
    setDocumentViewer(!documentViewer);
    setValue("addClientName", clientID);
    setValue("name", name);
    //setValue("category", category);
  };

  const [selectedDocumentCategoryArray, setSelectedDocumentCategoryArray] =
    useState(selectedClientDocumentsType?.toLowerCase());
  const [categoryUpdated, setCategoryUpdated] = useState(0);
  const [documentUpdatedToTrue, setDocumentUpdatedToTrue] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const [selectedClientId, setSelectedClientId] = useState(clientID);

  const [documentResponse, setDocumentResponse] = useState([]);
  const [selectedDocumentRequestedType, setSelectedDocumentRequestedType] =
    useState(beneficiary ? "allBeneficiary" : "allRequested");

  const [requestedDocumentType, setRequestedDocumentType] = useState("all");
  const [contactBeneficiary, setContactBeneficiary] = useState(beneficiary);
  const [beneficiaryAdded, setBeneficiaryAdded] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");

  let searchValue = globalSearch ? `&global_search=${globalSearch}` : "";
  let docStatus = `&document_status=${selectedDocumentRequestedType}`;
  let docCategory = `&document_category=${selectedDocumentCategoryArray}`;
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedDocCategoryType, setSelectedDocCategoryType] = useState("All");

  const [clientNameLabel, setClientNameLabel] = useState("");

  const { token, user_id, is_admin } = useContext(AuthContext);
  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  const canViewBeneficiaries = hasPermission("view_beneficiaries");
  const canViewRequestedDocuments = hasPermission("view_requested_documents");

  const fetchCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_type",
      });
      if (response.code == 200) {
        setCategoryList(response.data);
      } else {
        setCategoryList("");
      }
    } catch (error) {
      return error;
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      if (response.code == 200) {
        setSubCategoryList(response.data);
      } else {
        setSubCategoryList("");
      }
    } catch (error) {
      return error;
    }
  };

  const fetchRequestList = async (selectedClient) => {
    setLinkRequest(false);
    const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.code === 200) {
        setRequestOptions(data.data[0]?.alldocumentcases);
      } else {
        setRequestOptions("");
      }
    } catch (error) {
      return error;
    }
  };

  const getClientNameDetails = async () => {
    const clientNameResponse = await getClientName({
      contact_id: clientID,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data[0]?.name);
  };

  const handleRequestStatusButtonClick = (type) => {
    setSelectedDocumentRequestedType(type);
    setContactBeneficiary(false);
  };

  const handleCategoryChange = (e) => {
    setSelectedDocumentCategoryArray(e.target.value.toLowerCase());
    changeSelectedClientDocumentsType(e);
  };

  const handleDocumentCategoryChange = (e) => {
    setSelectedDocCategoryType(e.target.value);
  };

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const handleLinkRequest = (e) => {
    setLinkRequestOpen(true);
  };

  const setCategoryUpdatedToTrue = () => {
    setCategoryUpdated((count) => count + 1);
  };

  const documentResponseFunc = async () => {
    if (!categoryUpdated) {
      setLoading(true);
    }

    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: is_admin,
      userId: user_id,
      contact_id: selectedClientId,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();

    const url = `${baseUrl}/api/get-doc-request-data?${queryString}${docStatus}${docCategory}`;

    const response = await requestedDocumentList({ token, api: url });
    if (response.code == 200) {
      setTotalPages(
        Math.ceil(response?.data[0]?.alldocumentcasescount / pageSize)
      );
      setDocumentResponse(
        response?.data[0]?.alldocumentcasescount !== 0
          ? response?.data[0]?.alldocumentcases
          : []
      );
    } else {
      setTotalPages(1);
      setDocumentResponse([]);
    }

    if (!categoryUpdated) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchSubCategory();
    getClientNameDetails();
    getClienRefNo();
    if (clientID) {
      fetchRequestList(clientID);
    }
  }, []);

  useEffect(() => {
    if (clientID) {
      fetchRequestList(clientID);
    }
  }, [docReqID]);

  useEffect(() => {
    if (showUploads) {
      handleRequestStatusButtonClick("uploaded");
    }
  }, [showUploads]);

  useEffect(() => {
    fetchCategory();
    fetchSubCategory();
    documentResponseFunc();
    if (clientID) {
      fetchRequestList(clientID);
    }
  }, [
    categoryUpdated,
    documentUpdatedToTrue,
    bulkDeleteRequests,
    selectedDocumentRequestedType,
    selectedDocumentCategoryArray,
    currentPage,
    columnFilters,
    debouncedGlobalFilter,
    sorting,
    pageSize,
    selectedClientId,
  ]);

  const getClienRefNo = async () => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: clientID,
      token: token,
    });
    setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
  };

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    const editDocumentFormResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: docCaseID,
      document_desc: description,
      document_name: editDocumentFormData.name,
      //category: editDocumentFormData.category,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    });

    if (editDocumentFormResponse.code === 200) {
      setPdfViewerTitle(editDocumentFormData.name);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (
      !canViewRequestedDocuments &&
      selectedDocumentRequestedType === "allRequested"
    ) {
      handleRequestStatusButtonClick("uploaded");
    }
  }, [canViewRequestedDocuments]);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setApproveConfirmOpen={setApproveConfirmOpen}
        />
      )}
      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
        />
      )}

      {linkRequestOpen && (
        <LinkToRequest
          documentId={docCaseID}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setReqStatus}
          setRequestId={setDocReqID}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
        />
      )}

      {!documentViewer ? (
        <>
          <div
            className="case-doc-table-header flex-wrap
          gap-4 py-3
          lg:flex-nowrap lg:!py-0"
          >
            <div className="doc-buttons">
              {contactBeneficiary ||
              selectedDocumentRequestedType.toLowerCase() ===
                "allbeneficiary" ? (
                <AddBeneficiaryPopup
                  clientID={clientID}
                  setBeneficiaryAdded={setBeneficiaryAdded}
                  clientType={clientType}
                  typeOptions={typeOptions}
                />
              ) : selectedDocumentRequestedType?.toLowerCase() ===
                "allrequested" ? (
                <RequestDocumentsPopup
                  clientID={clientID}
                  setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                  clientType={clientType}
                  setSelectedClientId={setSelectedClientId}
                  // serviceSubType={serviceSubType}
                />
              ) : (
                <UploadDocumentsPopup
                  clientID={clientID}
                  documentUpdatedToTrue={documentUpdatedToTrue}
                  setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                />
              )}
            </div>

            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
              {canViewBeneficiaries && (
                <div className="border border-black rounded-md">
                  <Button
                    className={`p-3 capitalize  text-black shadow-none flex gap-2 bg-transparent rounded-md ${
                      selectedDocumentRequestedType === "allBeneficiary"
                        ? "bg-[#4fa6d11e] text-[#4fa6d1] hover:bg-[#4fa6d144]"
                        : "bg-transparent"
                    }`}
                    onClick={() =>
                      handleRequestStatusButtonClick("allBeneficiary")
                    }
                  >
                    <UsersRound
                      color={`${
                        selectedDocumentRequestedType === "allBeneficiary"
                          ? "#4FA6D1"
                          : "#000"
                      }`}
                    />
                    All Beneficiaries
                  </Button>
                </div>
              )}

              <div className="flex border border-black rounded-md">
                {canViewRequestedDocuments && (
                  <Button
                    className={`p-3 capitalize rounded-none text-black shadow-none flex gap-2  ${
                      selectedDocumentRequestedType === "allRequested"
                        ? "bg-[#4fa6d11e] text-[#4fa6d1] hover:bg-[#4fa6d144]"
                        : "bg-transparent"
                    }`}
                    onClick={() =>
                      handleRequestStatusButtonClick("allRequested")
                    }
                  >
                    <FilePenLineIcon
                      color={`${
                        selectedDocumentRequestedType === "allRequested"
                          ? "#4FA6D1"
                          : "#000"
                      }`}
                    />
                    Requested
                  </Button>
                )}
                <Button
                  className={`p-3 capitalize rounded-none text-black shadow-none flex gap-px rounded-tr-md rounded-br-md  ${
                    selectedDocumentRequestedType === "uploaded"
                      ? "bg-[#4fa6d11e] text-[#4fa6d1] hover:bg-[#4fa6d144]"
                      : "bg-transparent"
                  }`}
                  onClick={() => handleRequestStatusButtonClick("uploaded")}
                >
                  <FolderUp
                    color={`${
                      selectedDocumentRequestedType === "uploaded"
                        ? "#4FA6D1"
                        : "#000"
                    }`}
                  />
                  Uploaded
                </Button>
              </div>
            </div>
            <div
              className={`search-area ${
                selectedDocumentRequestedType === "allBeneficiary"
                  ? "invisible"
                  : "visible"
              }`}
            >
              <div className="case-doc-table-header-part">
                <FormControl className="case-doc-select-ui">
                  {selectedDocumentRequestedType?.toLowerCase() ===
                  "allrequested" ? (
                    <>
                      <InputLabel
                        className="case-doc-select-label"
                        id="select-label"
                      >
                        Document Request Type
                      </InputLabel>
                      <Select
                        name="addDocCategories"
                        sx={{ margin: "10px 0px", maxWidth: "20" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={selectedClientDocumentsType}
                        onChange={(e) => handleCategoryChange(e)}
                        label="Document Request Type"
                      >
                        {clientDocumentsType.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>
                      <InputLabel
                        className="case-doc-select-label"
                        id="select-label"
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="clientDocCategories"
                        sx={{ margin: "10px 0px", maxWidth: "20" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={selectedDocCategoryType}
                        onChange={(e) => handleDocumentCategoryChange(e)}
                        label="Category"
                      >
                        <MenuItem key="All" value="All">
                          All
                        </MenuItem>
                        {categoryList?.map((option) => (
                          <MenuItem
                            key={option.field_setting_id}
                            value={option.field_setting_id}
                          >
                            {option.fields_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </div>
            </div>
          </div>

          {selectedDocumentRequestedType === "allRequested" &&
          canViewRequestedDocuments ? (
            <div className="document__table">
              <RequestedDocumentsTable
                clientID={selectedClientId}
                setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                documentResponse={documentResponse}
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                pageSize={pageSize}
                setPageSize={setPageSize}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                bulkDeleteRequests={bulkDeleteRequests}
                setBulkDeleteRequests={setBulkDeleteRequests}
                sorting={sorting}
                setSorting={setSorting}
              />
            </div>
          ) : selectedDocumentRequestedType === "allBeneficiary" ? (
            <BeneficiaryTable
              clientID={clientID}
              beneficiaryAdded={beneficiaryAdded}
              setBeneficiaryAdded={setBeneficiaryAdded}
              typeOptions={typeOptions}
            />
          ) : (
            <DocumentTable
              clientID={clientID}
              selectedDocCategoryType={selectedDocCategoryType}
              changeDocumentViewerState={changeDocumentViewerState}
              documentUpdatedToTrue={documentUpdatedToTrue}
              setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
            />
          )}
        </>
      ) : (
        <div className="all-document-pdf-viewer">
          <div className="all-document-pdf-viewer-header">
            <div className="capitalize all-document-pdf-viewer-title">
              {pdfViewerTitle}
              {selectedRequestLabel
                ? " - " + selectedRequestLabel + " (Linked Request)"
                : null}
            </div>
            <div className="document-pdf-actions">
              {docReqID && reqStatus?.toLowerCase() === "approved" && (
                <div
                  className="all-document-pdf-viewer-icon !cursor-default"
                  style={{ color: "green", fontSize: 26 }}
                  title="Approved"
                >
                  <TbCircleCheck />
                </div>
              )}
              {docReqID && reqStatus?.toLowerCase() === "rejected" && (
                <div
                  className="all-document-pdf-viewer-icon !cursor-default"
                  style={{ color: "red", fontSize: 29 }}
                  title="Rejected"
                >
                  <TiDeleteOutline />
                </div>
              )}

              <ButtonUI
                formButton={false}
                variant="outlined"
                buttonText={<TbCircleCheck />}
                style={{
                  backgroundColor:
                    reqStatus?.toLowerCase() === "uploaded"
                      ? "#28a745"
                      : "#d8d8e4df",

                  color:
                    reqStatus?.toLowerCase() === "uploaded"
                      ? "white"
                      : "rgb(18 215 24)",
                  marginRight: "5px",
                  marginBottom: "5px",
                  padding: "8px",
                  minWidth: "fit-content",
                  border: "none",
                }}
                disabled={
                  reqStatus?.toLowerCase() === "uploaded" ? false : true
                }
                onClickFunc={handleApproveDocument}
              />
              <ButtonUI
                formButton={false}
                variant="outlined"
                buttonText={<TiDeleteOutline />}
                style={{
                  backgroundColor:
                    reqStatus?.toLowerCase() === "uploaded"
                      ? "#dc3545"
                      : "#d8d8e4df",

                  color:
                    reqStatus?.toLowerCase() === "uploaded"
                      ? "white"
                      : "rgb(215 18 18)",
                  marginRight: "5px",
                  marginBottom: "5px",
                  padding: "8px",
                  minWidth: "fit-content",
                  border: "none",
                }}
                disabled={
                  reqStatus?.toLowerCase() === "uploaded" ? false : true
                }
                onClickFunc={handleDisapproveDocument}
              />
              <ButtonUI
                formButton={false}
                variant="outlined"
                buttonText={<BiLinkAlt />}
                style={{
                  backgroundColor:
                    !docReqID && requestOptions?.length > 0
                      ? "#17a2b8"
                      : "#d8d8e4df",
                  color:
                    !docReqID && requestOptions?.length > 0
                      ? "white"
                      : "rgb(18 215 24)",
                  marginRight: "5px",
                  marginBottom: "5px",
                  padding: "8px",
                  minWidth: "fit-content",
                  border: "none",
                }}
                disabled={
                  !docReqID && requestOptions?.length > 0 ? false : true
                }
                onClickFunc={handleLinkRequest}
              />

              <div
                className="all-document-pdf-viewer-icon"
                title="Close Document"
                onClick={() => setDocumentViewer(false)}
              >
                <RiCloseCircleFill />
              </div>
            </div>
          </div>
          <div className="document-viewer-with-from">
            {documentType === "jpg" || documentType === "png" ? (
              <OcrContainer documentLink={pdfLink} setOcrData={setOcrData} />
            ) : (
              <div className="iframe-wrapper">
                <iframe
                  title={pdfViewerTitle}
                  src={pdfLink}
                  width="100%"
                  height="590"
                ></iframe>
              </div>
            )}

            <div className="document-viewer-form">
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    control={control}
                    name="addClientName"
                    disabled
                    render={({ field }) => (
                      <Autocomplete
                        className="clientNameAuto"
                        name="addClientName"
                        options={clientNames?.map(
                          (clientName) => clientName.name
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              !clientNameLabel ? "Client Name" : clientNameLabel
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            {...params} // spread the params object to display the label
                          />
                        )}
                        // filterOptions={(options, state) => {
                        //   return options.filter((option) => {
                        //     return (
                        //       option
                        //         .toLowerCase()
                        //         .indexOf(state.inputValue.toLowerCase()) > -1
                        //     );
                        //   });
                        // }}
                        {...field}
                        {...register("addClientName")}
                        value={
                          clientNames?.find(
                            (clientName) =>
                              clientName.contact_id === field.value
                          )?.name
                        }
                        //   onChange={(event, newValue) => {
                        //     const selectedClient = clientNames?.find(
                        //       (clientName) => clientName.name === newValue
                        //     );
                        //     if (selectedClient) {
                        //       field.onChange(selectedClient.contact_id);
                        //     }
                        //   }}
                      />
                    )}
                  />
                  <span className="error-message">
                    {errors["addClientName"]?.message}
                  </span>
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="caseId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        disabled
                        name="caseId"
                        label="Client Reference Number"
                        type="text"
                        value={value ? value : ""}
                        setValue={onChange}
                        variant="outlined"
                        register={register}
                        cssStyles={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        name="name"
                        label="Document Name"
                        type="text"
                        value={value ? value : ""}
                        setValue={onChange}
                        variant="outlined"
                        register={register}
                        cssStyles={{
                          margin: "20px 0px",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                  <span className="error-message">
                    {errors["name"]?.message}
                  </span>
                </div>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputUI
                        name="category"
                        label="Category"
                        type="text"
                        value={category ? category : ""}
                        disabled
                        variant="outlined"
                        register={register}
                        cssStyles={{
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_wrapper">
                <FormControl fullWidth>
                  <InputLabel id="select-label">Sub Category</InputLabel>
                  <Controller
                    control={control}
                    name="subcategory"
                    defaultValue={subCategory}
                    render={({ field: { onChange, value } }) => (
                      <SelectBox
                        name="subcategory"
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={value}
                        onChange={onChange}
                        label="Sub Category"
                        {...register("subcategory")}
                      >
                        {subCategoryList?.map((category) => (
                          <MenuItem
                            key={category.field_setting_id}
                            value={category.field_setting_id}
                          >
                            {category.fields_name}
                          </MenuItem>
                        ))}
                      </SelectBox>
                    )}
                  />
                </FormControl>
                <span className="error-message">
                  {errors["subcategory"]?.message}
                </span>
              </div>
              <div className="field_wrapper">
                <div className="field">
                  <TextField
                    name="description"
                    label="Description"
                    multiline
                    minRows={3}
                    maxRows={3}
                    value={ocrData || ""}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    fullWidth
                  />
                </div>
              </div>
              <ButtonUI
                variant="contained"
                buttonText="Submit"
                handleSubmit={handleSubmit}
                onFormSubmit={onEditDocumentFormSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestedDocumentsList;
