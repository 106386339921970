import React, { useMemo, useState, useEffect } from "react";
import TableSkeleton from "../../components/common/TableSkeleton";
import DataToolbar from "../common/DataToolbar";
import PaginationSection from "../../components/common/PaginationSection";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "../../shadcn-components/ui/button";
import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn-components/ui/select";
import { CaretSortIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import StatusPopup from "./StatusPopup";
import EditRolePopup from "./EditRolePopup";
import DeleteRolePopup from "./DeleteRolePopup";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-components/ui/tooltip";
import "../../styles/Table.css";

const RoleTable = ({
  roleTableData,
  setRoleAddedToTrue,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
  currentPage,
  totalPages,
  showFilterButton,
  setShowFilterButton,
  setPageSize,
  loading,
  isError,
  columnFilters,
  setColumnFilters,
  sorting,
  setSorting,
  setBulkDeleteRoleIds,
  setBulkDeleteRole,
  rowSelection,
  setRowSelection,
}) => {
  const [filterInput, setFilterInput] = useState(globalFilter);
  const [selectAllChecked, setSelectAllChecked] = useState();
  const [columnVisibility, setColumnVisibility] = useState({});

  const data = useMemo(() => roleTableData || [], [roleTableData]);

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          {(table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="p-0 !border-none !shadow-none bg-transparent"
                >
                  <ChevronDownIcon className="" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            setSelectAllChecked(true);
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "name",
      displayName: "Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "status",
      displayName: "Status",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {row.getValue("status")?.toLowerCase() === "active"
            ? "Active"
            : "Inactive"}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id).toLowerCase());
      },
    },
    {
      accessorKey: "updated_at",
      displayName: "Modified At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Modified At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updated_at")}</div>,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      displayName: "Actions",
      enableHiding: false,
      header: ({ column }) => (
        <Button variant="ghost" className="p-0 !cursor-default">
          Actions
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-px">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <StatusPopup
                    setRoleAddedToTrue={setRoleAddedToTrue}
                    tableValues={row.original}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Change Status</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <EditRolePopup
                    setRoleAddedToTrue={setRoleAddedToTrue}
                    tableValues={row.original}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Edit</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span>
                  <DeleteRolePopup
                    setRoleAddedToTrue={setRoleAddedToTrue}
                    id={row.original.role_id}
                    setFilterInput={setFilterInput}
                    setGlobalFilter={setGlobalFilter}
                  />
                </span>
              </TooltipTrigger>
              <TooltipContent className="bg-gray-600">
                <p>Delete</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
  ];

  const tableInstance = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
      pagination: {
        pageIndex: currentPage - 1,
        pageSize: 20,
      },
    },

    manualPagination: true,
    manualSorting: true,
    // globalFilterFn: (rows, columnFilters) => {
    // 	const [globalFilter] = columnFilters.filter(
    // 		(filter) => filter.id === "global"
    // 	);
    // 	if (!globalFilter || !globalFilter.value) return rows;

    // 	return rows.filter((row) => {
    // 		const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
    // 		return name.includes(globalFilter.value.toLowerCase());
    // 	});
    // },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === "function"
          ? updater(tableInstance.getState().pagination)
          : updater;
      setCurrentPage(newState.pageIndex + 1);
      setPageSize(newState.pageSize);
    },
  });

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = tableInstance
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const ids = selectedRowsData.map((row) => row.role_id).join(",");
    if (actionType === "delete") {
      setBulkDeleteRoleIds(ids);
      setBulkDeleteRole(true);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setGlobalFilter(filterInput);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [filterInput, setGlobalFilter]);

  return (
    <div className="table-content">
      <div className="document__table mt-3">
        <div className="w-full bg-white px-2">
          <div className="flex justify-between items-center py-4 flex-wrap gap-4">
            <Input
              placeholder="Search..."
              value={filterInput}
              onChange={(event) => setFilterInput(event.target.value)}
              className="max-w-sm"
              disabled={loading}
            />
            <div className="flex gap-2">
              <Button
                variant="outline"
                className=""
                onClick={() => setShowFilterButton(!showFilterButton)}
                disabled={loading}
              >
                Filter
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    className="ml-auto"
                    disabled={loading}
                  >
                    Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {tableInstance
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .map((column) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) =>
                            column.toggleVisibility(!!value)
                          }
                        >
                          {column.columnDef.displayName}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          {showFilterButton && (
            <DataToolbar
              table={tableInstance}
              apiUrl="/api/get-role-filter-name"
            />
          )}

          <div className="rounded-md border">
            <Table>
              <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                {tableInstance.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      <TableSkeleton />
                    </TableCell>
                  </TableRow>
                ) : isError || data.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ) : (
                  tableInstance.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
            <div className="flex items-center gap-5">
              <div className="flex-1 text-sm text-muted-foreground">
                {tableInstance.getFilteredSelectedRowModel().rows.length} of{" "}
                {tableInstance.getFilteredRowModel().rows.length} row(s)
                selected.
              </div>
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium">Rows per page</p>
                <Select
                  value={`${tableInstance.getState().pagination.pageSize}`}
                  onValueChange={(value) => {
                    setPageSize(Number(value));
                    setCurrentPage(1);
                  }}
                >
                  <SelectTrigger className="w-auto">
                    <SelectValue
                      placeholder={tableInstance.getState().pagination.pageSize}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                        <SelectItem key={pageSize} value={`${pageSize}`}>
                          {pageSize}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="space-x-2">
              <PaginationSection
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleTable;
