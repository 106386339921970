import React, { useState, useContext } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import changeRoleStatus from "../../api/editRole";
import { AuthContext } from "../../context/Auth/AuthContext";

const StatusPopup = ({ setRoleAddedToTrue, tableValues }) => {
    const [open, setOpen] = useState(false);
    const { token, userID } = useContext(AuthContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeStatusFormSubmit = async () => {
        const changeStatusFormResponse = await changeRoleStatus({
            token: token,
            role_id: tableValues.role_id,
            status: tableValues.status === "active" ? "inactive" : "active",
        });

        if (changeStatusFormResponse?.code === 200) {
            setRoleAddedToTrue();
            toast.success("Role Status is successfully changed", {
                position: "top-center",
                autoClose: 3000,
                theme: "dark",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error("Error while changing role status", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setOpen(false);
    };

    return (
        <div className="dialog-modal">
            <ButtonUI
                formButton={false}
                variant="outlined"
                buttonText={
                    tableValues.status === "inactive" ? (
                        <HiOutlineCheckCircle />
                    ) : (
                        <RiCloseCircleLine />
                    )
                }
                style={{
                    backgroundColor:
                        tableValues.status === "inactive" ? "#28a745" : "#ffc107",
                    color: "white",
                    marginRight: "5px",
                    padding: "8px",
                    minWidth: "fit-content",
                    border: "none",
                }}
                onClickFunc={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
                    <div className="popup-header-svg-icon">
                        {tableValues.status === "inactive" ? (
                            <HiOutlineCheckCircle />
                        ) : (
                            <RiCloseCircleLine />
                        )}
                    </div>
                    <div className="popup-header-title">Change Role Status</div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to change role status ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "flex-end" }}>
                    <ButtonUI
                        formButton={false}
                        variant="outlined"
                        buttonText="No"
                        style={{
                            margin: "15px 0px",
                            backgroundColor: "#fff",
                            color: "#007bff",
                            border: "1px solid #007bff",
                        }}
                        onClickFunc={handleClose}
                    />
                    <ButtonUI
                        formButton={false}
                        variant="outlined"
                        buttonText="Yes"
                        style={{
                            margin: "15px",
                            backgroundColor: "#fff",
                            color: "##007bff",
                            border: "1px solid #007bff",
                        }}
                        autoFocus={true}
                        onClickFunc={() => {
                            onChangeStatusFormSubmit();
                        }}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StatusPopup;
