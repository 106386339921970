import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";

const EditRoleForm = ({
  register,
  control,
  setValue,
  watch,
  errors,
  tableValues,
}) => {
  const statusOptions = [
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
  ];
  const initialPermissions = useMemo(
    () =>
      tableValues?.permission_name
        ? tableValues?.permission_name?.split(",")
        : [],
    [tableValues?.permission_name]
  );

  const permissions = watch("permissions"); // Watch permissions array

  useEffect(() => {
    // Avoid setting if permissions are already initialized
    if (permissions.length === 0) {
      setValue("permissions", initialPermissions);
    }
  }, [initialPermissions, setValue]);

  const permissionCategories = [
    {
      name: "All Documents",
      children: [
        { name: "View Documents", value: "view_all_documents" },
        { name: "Create Document", value: "create_all_documents" },
        { name: "Edit Document", value: "edit_all_documents" },
        { name: "Delete Document", value: "delete_all_documents" },
        { name: "Share Document", value: "share_all_documents" },
        { name: "Download Document", value: "download_all_documents" },
        { name: "Copy Document", value: "copy_all_documents" },
        { name: "Edit Pdf", value: "edit_pdf_documents" },
        { name: "Link Document", value: "link_documents" },
        { name: "Document Approval", value: "document_approval" },
        { name: "Client Edit", value: "client_edit" },
      ],
    },
    {
      name: "Clients",
      children: [
        { name: "View Clients", value: "view_clients" },
        { name: "Create Client", value: "create_clients" },
        { name: "Edit Client", value: "edit_clients" },
        { name: "Delete Client", value: "delete_clients" },
        { name: "View Requested Documents", value: "view_requested_documents" },
        {
          name: "Approve Requested Documents",
          value: "approve_requested_documents",
        },
        {
          name: "Reject Requested Documents",
          value: "reject_requested_documents",
        },
        {
          name: "Download Requested Documents",
          value: "download_requested_documents",
        },
        {
          name: "Delete Requested Documents",
          value: "delete_requested_documents",
        },
        { name: "View Beneficiaries", value: "view_beneficiaries" },
        { name: "Create Beneficiary", value: "create_beneficiaries" },
        { name: "Edit Beneficiary", value: "edit_beneficiaries" },
        { name: "Delete Beneficiary", value: "delete_beneficiaries" },
      ],
    },
    {
      name: "Mailroom",
      children: [
        { name: "All Assigned", value: "view_all_assigned" },
        { name: "All Unassigned", value: "view_all_unassigned" },
        { name: "My Assigned", value: "view_my_assigned" },
        { name: "Incoming Emails", value: "view_incoming_emails" },
        { name: "Incoming Faxes", value: "view_incoming_faxes" },
        { name: "Scanned Files", value: "view_scanned_files" },
      ],
    },
    {
      name: "Users",
      children: [
        { name: "View Users", value: "view_users" },
        { name: "Create User", value: "create_users" },
        { name: "Edit User", value: "edit_users" },
        { name: "Delete User", value: "delete_users" },
      ],
    },
    {
      name: "Document Requests",
      children: [
        { name: "View Document Requests", value: "view_document_requests" },
        { name: "Create Document Request", value: "create_document_requests" },
        { name: "Edit Document Request", value: "edit_document_requests" },
        { name: "Delete Document Request", value: "delete_document_requests" },
      ],
    },
    {
      name: "Field Settings",
      children: [
        { name: "View Field Settings", value: "view_field_settings" },
        { name: "Create Field Setting", value: "create_field_settings" },
        { name: "Edit Field Setting", value: "edit_field_settings" },
        { name: "Delete Field Setting", value: "delete_field_settings" },
      ],
    },
    {
      name: "Permanent Delete",
      children: [
        { name: "View Delete Documents", value: "view_trash_documents" },
        { name: "View Delete Users", value: "view_trash_users" },
        { name: "Delete Documents", value: "delete_documents_permanently" },
        { name: "Delete Users", value: "delete_users_permanently" },
        { name: "Restore Users", value: "restore_users" },
        { name: "Restore Documents", value: "restore_documents" },
      ],
    },
  ];

  const handleCategoryChange = (category, isChecked) => {
    if (isChecked) {
      const newPermissions = [
        ...permissions,
        ...category.children.map((child) => child.value),
      ];
      setValue("permissions", [...new Set(newPermissions)]);
    } else {
      const newPermissions = permissions.filter(
        (permission) =>
          !category.children.some((child) => child.value === permission)
      );
      setValue("permissions", newPermissions);
    }
  };

  const handlePermissionChange = (permission) => {
    const isChecked = permissions.includes(permission.value);
    if (isChecked) {
      const newPermissions = permissions.filter((p) => p !== permission.value);
      setValue("permissions", newPermissions);
    } else {
      const newPermissions = [...permissions, permission.value];

      setValue("permissions", [...new Set(newPermissions)]);
    }
  };

  return (
    <Grid className="">
      <div className="edit-document-form grid grid-cols-1">
        <div className="field_wrapper flex gap-2">
          <div className="field w-full">
            <div className="input-field">
              <Controller
                name="name"
                defaultValue={tableValues.name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="name"
                    label="Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">{errors["name"]?.message}</span>
          </div>
          <div className="field w-full">
            <FormControl fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status ?? "inactive"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="permission-categories">
              {permissionCategories.map((category) => (
                <div key={category.name} className="category">
                  <div className="category-title">
                    <input
                      type="checkbox"
                      id={category.name}
                      onChange={(e) =>
                        handleCategoryChange(category, e.target.checked)
                      }
                      checked={category.children.every((child) =>
                        permissions.includes(child.value)
                      )}
                    />
                    <strong>
                      <label
                        htmlFor={category.name}
                        style={{ marginLeft: "3px" }}
                      >
                        {category.name}
                      </label>
                    </strong>
                  </div>
                  <div className="category-permissions">
                    {category.children.map((permission) => (
                      <div
                        key={permission.value}
                        className="permission"
                        style={{
                          display: "inline-block",
                          margin: "5px 10px",
                          width: "30%",
                        }}
                      >
                        <input
                          type="checkbox"
                          id={permission.value}
                          onChange={() => handlePermissionChange(permission)}
                          checked={permissions.includes(permission.value)}
                        />
                        <label
                          htmlFor={permission.value}
                          style={{ marginLeft: "3px" }}
                        >
                          {permission.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <span className="error-message">
              {errors["permissions"]?.message}
            </span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditRoleForm;
