import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import deleteClient from "../../../../api/deleteClient";
import { Link1Icon, PlusIcon, MinusIcon } from "@radix-ui/react-icons";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shadcn-components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shadcn-components/ui/select";
import { Button } from "../../../../shadcn-components/ui/button";
import { Input } from "../../../../shadcn-components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../shadcn-components/ui/form";
import { Separator } from "../../../../shadcn-components/ui/separator";
import addClient from "../../../../images/add-user.png";
import addAdditionalContact from "../../../../api/addAdditionalContacts";
import getAllSubTypeList from "../../../../api/getAllSubTypeList";
import { UserPlus } from "lucide-react";
import { useRoleContext } from "../../../../context/RolesContext";

// Define schema for form validation
const formSchema = z.object({
  contacts: z.array(
    z.object({
      name: z
        .string()
        .min(2, { message: "Name must be at least 2 characters." }),
      email_id: z.string().email({ message: "Invalid email address." }),
      phone_number: z
        .string()
        .min(10, { message: "Phone number must be at least 10 characters." }),
      linked_client_id: z.string(),
      type: z.string().min(1, { message: "Client Category is required." }),
      sub_type: z
        .string()
        .min(1, { message: "Client Sub Category is required." }),
    })
  ),
});

const AddBeneficiaryPopup = ({
  clientID,
  setBeneficiaryAdded,
  typeOptions,
}) => {
  const [open, setOpen] = useState(false);
  const { is_admin, token } = useContext(AuthContext);
  const [subTypeOptions, setSubTypeOptions] = useState([]);

  const clientSubTypeLoad = async (selectedClientType) => {
    const typeId = selectedClientType;
    const data = await getAllSubTypeList({ token, typeId });
    if (data.code == 200) {
      const filteredTypeList =
        data?.data !== null
          ? data?.data.map((type) => {
              return {
                label: type.fields_name,
                typeId: type.field_setting_id,
              };
            })
          : [];
      filteredTypeList.unshift({ label: "None", typeId: "None" });
      setSubTypeOptions(filteredTypeList);
    } else {
      setSubTypeOptions([]);
    }
  };

  const { permissions } = useRoleContext();
  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      contacts: [
        {
          name: "",
          email_id: "",
          phone_number: "",
          linked_client_id: clientID || "",
          type: "",
          sub_type: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "contacts",
  });

  const onSubmit = async (formData) => {
    console.log(formData);
    const response = await addAdditionalContact({ token, formData });

    if (response[0].code === 200) {
      form.reset({
        contacts: [
          {
            name: "",
            email_id: "",
            phone_number: "",
            linked_client_id: clientID,
            type: "",
            sub_type: "",
          },
        ],
      });
      setOpen(false);
      setBeneficiaryAdded((prev) => !prev);
      toast.success("Beneficiary added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response[0].code === 400) {
      toast.error(`${response[0].error}`, {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(`Something went wrong please try again !`, {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dialog-modal">
      {hasPermission("create_beneficiaries") && (
        <Dialog
          open={open}
          onOpenChange={setOpen}
          className="max-w-[550px] max-h-[95%]"
        >
          <DialogTrigger asChild>
            <Button
              variant="outline"
              className="bg-bg_app text-white hover:text-white hover:bg-bg_app"
              onClick={handleClickOpen}
            >
              Add Beneficiary
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[650px] max-h-[95%] overflow-y-auto">
            <DialogHeader>
              <div className="flex gap-1 items-center">
                <img
                  src={addClient}
                  alt="user icon"
                  className="h-auto w-[120px] object-contain"
                />
                <DialogTitle className="flex-[1] text-left text-[#007bff] text-xl">
                  Add Beneficiary
                </DialogTitle>
              </div>
            </DialogHeader>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                {fields.map((item, index) => (
                  <div key={item.id} className="flex flex-col gap-4">
                    {index > 0 && <Separator className="my-4" />}
                    {index > 0 && (
                      <div className="flex justify-end">
                        <Button
                          type="button"
                          onClick={() => remove(index)}
                          className="p-2 bg-blue-500 rounded-full text-white h-9 w-9"
                        >
                          <MinusIcon />
                        </Button>
                      </div>
                    )}
                    <div className="flex items-center space-x-2">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].name`}
                        render={({ field }) => (
                          <FormItem className="flex-1">
                            <FormLabel className="m-0">Name</FormLabel>
                            <FormControl>
                              <Input placeholder="John Doe" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].email_id`}
                        render={({ field }) => (
                          <FormItem className="flex-1">
                            <FormLabel className="m-0">Email</FormLabel>
                            <FormControl>
                              <Input
                                placeholder="john.doe@example.com"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].phone_number`}
                        render={({ field }) => (
                          <FormItem className="flex-1">
                            <FormLabel className="m-0">Phone</FormLabel>
                            <FormControl>
                              <Input placeholder="123-456-7890" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex items-center space-x-2 w-full">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].type`}
                        render={({ field, onChange }) => (
                          <FormItem className="w-full">
                            <FormLabel>Client Category</FormLabel>
                            <Select
                              //onValueChange={field.onChange}
                              onValueChange={(value) => {
                                field.onChange(value); // Update the form value
                                clientSubTypeLoad(value); // Call the clientSubTypeLoad function
                              }}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a category" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {typeOptions?.map((option) => {
                                  return (
                                    <SelectItem
                                      value={option.typeId}
                                      key={option.typeId}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  );
                                })}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="flex items-center space-x-2 w-full">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].sub_type`}
                        render={({ field }) => (
                          <FormItem className="w-full">
                            <FormLabel>Client Sub Category</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a client sub category" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {subTypeOptions?.map((option) => {
                                  return (
                                    <SelectItem
                                      value={option.typeId}
                                      key={option.typeId}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  );
                                })}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="hidden">
                      <FormField
                        control={form.control}
                        name={`contacts[${index}].linked_client_id`}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input type="hidden" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    {index === fields.length - 1 && (
                      <div className="flex justify-end mt-3">
                        <Button
                          type="button"
                          onClick={() =>
                            append({
                              name: "",
                              email_id: "",
                              phone_number: "",
                              linked_client_id: clientID,
                              type: "",
                              sub_type: "",
                            })
                          }
                          className="p-2 bg-blue-500 rounded-full text-white h-9 w-9"
                        >
                          <PlusIcon />
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
                <DialogFooter>
                  <Button type="submit" className="bg-[#007bff]">
                    Save Changes
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default AddBeneficiaryPopup;
