import React, { useState, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../components/common/Navbar";
import RoleTable from "../components/role-table/RoleTable";
import { AuthContext } from "../context/Auth/AuthContext";
import AddRole from "../components/role-table/AddRole";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn-components/ui/tooltip";
import { Button } from "../shadcn-components/ui/button";

import BulkDeletePopup from "../components/role-table/BulkDeletePopup";

import baseUrl from "../config/baseUrl";
import roleData from "../api/user";

const Roles = () => {
  return <RoleComponent />;
};

const RoleComponent = () => {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [showAddRoleForm, setShowAddRoleForm] = useState(false);
  const [roleAdded, setRoleAdded] = useState(0);
  const [bulkDeleteRole, setBulkDeleteRole] = useState(false);
  const [bulkDeleteRoleIds, setBulkDeleteRoleIds] = useState();

  const { token, is_admin, user_id } = useContext(AuthContext);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");

  const [showFilterButton, setShowFilterButton] = useState(false);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      "roleData",
      roleAdded,
      globalFilter,
      currentPage,
      columnFilters,
      pageSize,
      sorting,
    ],
    queryFn: async () => {
      setLoading(true);
      const offset = currentPage - 1;
      const filterParams = {};

      columnFilters.forEach((filterItem) => {
        filterParams[filterItem.id] = filterItem.value;
      });

      const sortParam = sorting
        .map((sortItem) => `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`)
        .join(",");

      const params = {
        isAdmin: is_admin,
        userId: user_id,
        limit: pageSize,
        offset: offset,
        ...filterParams,
        global_search: globalFilter,
        sort: sortParam,
      };

      const queryString = new URLSearchParams(params).toString();

      const url = `${baseUrl}/api/get-roles?${queryString}`;
      const response = await roleData({ token, api: url });
      setLoading(false);

      if (response.code !== 200) {
        throw new Error("Network response was not ok");
      }
      setTotalPages(Math.ceil(response?.user_count / pageSize));
      return response;
    },
    keepPreviousData: true,
  });

  const onAddRoleButtonClick = () => {
    setShowAddRoleForm(!showAddRoleForm);
  };

  const setRoleAddedToTrue = () => {
    setRoleAdded((count) => count + 1);
    refetch();
  };

  // const table = useReactTable({
  //   data: data?.data,
  //   columns,
  //   onSortingChange: setSorting,
  //   onColumnFiltersChange: setColumnFilters,
  //   getCoreRowModel: getCoreRowModel(),
  //   initialState: {
  //     pageSize: 20,
  //   },
  //   manualPagination: true,
  //   manualSorting: true,
  //   getSortedRowModel: getSortedRowModel(),
  //   getFilteredRowModel: getFilteredRowModel(),
  //   onColumnVisibilityChange: setColumnVisibility,
  //   onRowSelectionChange: setRowSelection,
  //   state: {
  //     sorting,
  //     columnFilters,
  //     columnVisibility,
  //     rowSelection,
  //     pageSize,
  //   },

  //   globalFilterFn: (rows, columnFilters) => {
  //     const [globalFilter] = columnFilters.filter(
  //       (filter) => filter.id === "global"
  //     );
  //     if (!globalFilter || !globalFilter.value) return rows;

  //     return rows.filter((row) => {
  //       const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
  //       return name.includes(globalFilter.value.toLowerCase());
  //     });
  //   },
  //   onGlobalFilterChange: (filterValue) => {
  //     setGlobalFilter(filterValue); // Update the global filter state
  //   },
  // });

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        {showAddRoleForm ? (
          <AddRole
            onAddRoleButtonClick={onAddRoleButtonClick}
            setRoleAddedToTrue={setRoleAddedToTrue}
          />
        ) : (
          <div className="user-content">
            {is_admin === "1" && (
              <button className="add-document" onClick={onAddRoleButtonClick}>
                Add Role
              </button>
            )}
            <div className="user-table-content">
              <RoleTable
                pageCount={pageCount}
                loading={isLoading}
                roleTableData={data?.data || []} // Ensure roleTableData is always an array
                setRoleAddedToTrue={setRoleAddedToTrue}
                // table={table}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                //columns={columns}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={totalPages}
                showFilterButton={showFilterButton}
                setShowFilterButton={setShowFilterButton}
                setPageSize={setPageSize}
                isError={isError}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                setBulkDeleteRoleIds={setBulkDeleteRoleIds}
                setBulkDeleteRole={setBulkDeleteRole}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
              />
            </div>
          </div>
        )}
      </div>
      {bulkDeleteRole && (
        <BulkDeletePopup
          Rolebulkid={bulkDeleteRoleIds}
          bulkDeleteRole={bulkDeleteRole}
          setBulkDeleteRole={setBulkDeleteRole}
          setRowSelection={setRowSelection}
          setRoleAddedToTrue={setRoleAddedToTrue}
        />
      )}
    </div>
  );
};

export default Roles;
