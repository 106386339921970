import React, { useState, useEffect, useContext } from "react";
import documentData from "../../api/documents";
import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
  MixerHorizontalIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@radix-ui/react-icons";
import { Button } from "../../shadcn-components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-components/ui/tooltip";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn-components/ui/select";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useRoleContext } from "../../context/RolesContext";
import AddDocument from "./AddDocument";
import Loading from "../material-ui/Loading";
import baseUrl from "../../config/baseUrl";
import "../../styles/DocumentTable.css";
import EditDocumentPopup from "./EditDocumentPopup";
import DeleteDocumentPopup from "./DeleteDocumentPopup";
import markDocumentAsFavourite from "../../api/addFavouriteDocument";
import removeDocumentFromFavourite from "../../api/removeFavouriteDocument";
import DeleteIcon from "@mui/icons-material/Delete";
import markDocumentsAsFavourite from "../../api/addBulkFavouriteDocument";
import clientsData from "../../api/clients";
import getAllFieldTypeList from "../../api/getAllFieldTypeList";
import { fileIcon } from "../common/FileIcons";
import DataToolbar from "../common/DataToolbar";
import PaginationSection from "../common/PaginationSection";
import TableSkeleton from "./TableSkeleton";
import BulkDeletePopup from "./BulkDeletePopup";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { useDebounce } from "use-debounce";

const DocumentTable = ({
  addDocumentTab,
  showAddDocumentForm,
  onAddDocumentButtonClick,
}) => {
  const [loading, setLoading] = useState(true);
  const [allFavourite, setAllFavourite] = useState(false);

  const [documentResponse, setDocumentResponse] = useState([]);

  // const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  // const [clientNames, setClientNames] = useState([]);
  const { permissions } = useRoleContext();

  const { token, id, is_admin } = useContext(AuthContext);

  const [documentAdded, setDocumentAdded] = useState(0);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [favouriteDocuments, setFavouriteDocuments] = useState(false);

  const [bulkFavouriteDocument, setBulkFavouriteDocument] = useState(false);
  const [bulkDeleteDocument, setBulkDeleteDocument] = useState(false);

  const [bulkDeleteDocIds, setBulkDeleteDocIds] = useState();

  //mew states
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);
  const [selectAllChecked, setSelectAllChecked] = useState();
  const [showFilterButton, setShowFilterButton] = useState(false);

  const hasPermission = (permission) => {
    if (is_admin === "1") {
      return true;
    }
    return permissions?.includes(permission);
  };

  const canCreate = hasPermission("create_all_documents");
  const canEdit = hasPermission("edit_all_documents");
  const canDelete = hasPermission("delete_all_documents");
  const canShare = hasPermission("share_all_documents");
  const canDownload = hasPermission("download_all_documents");

  const shouldShowActionColumn = canEdit || canDelete;
  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <div className="flex items-center">
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && "indeterminate")
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
          />
          {(table.getIsSomePageRowsSelected() ||
            table.getIsAllPageRowsSelected()) && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="p-0 !border-none !shadow-none bg-transparent"
                >
                  <ChevronDownIcon />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
                  Delete
                </DropdownMenuItem>
                <DropdownMenuItem
                  onSelect={() => handleBulkAction("favourite")}
                >
                  Mark Favourite
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            setSelectAllChecked(true);
            row.toggleSelected(!!value);
          }}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "favourite",
      displayName: "Favourite",
      header: ({ table }) => <StarIcon />,
      cell: ({ row }) =>
        row.getValue("favourite") === "1" ? (
          <StarFilledIcon
            className="text-yellow-500 cursor-pointer"
            onClick={() => handleFavourites(row.original)}
          />
        ) : (
          <StarIcon
            className="cursor-pointer"
            onClick={() => handleFavourites(row.original)}
          />
        ),
      enableSorting: false,
      enableHiding: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "document_type",
      displayName: "Type",
      filterable: true,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Type
            <CaretSortIcon className="ml-2 h-6" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="w-full flex items-center">
          {fileIcon[row.getValue("document_type")]}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_name",
      displayName: "Document Name",
      filterable: false,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Document Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const document_name = row.getValue("document_name");

        return (
          <div
            className="capitalize text-left underline cursor-pointer text-blue-500"
            onClick={() => {
              addDocumentTab(row.original);
            }}
          >
            {document_name}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableHiding: false,
    },
    {
      accessorKey: "name",
      displayName: "Client Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_request_setting_name",
      displayName: "Linked Request",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Linked Request
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const document_request_name = row.getValue(
          "document_request_setting_name"
        );

        return (
          <div className="flex text-left items-center justify-start">
            <span className="flex items-center justify-center case-doc-tables mr-4">
              {row.original.document_request_status ? (
                row.original.document_request_status?.toLowerCase() ===
                "requested" ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <span className="request-doc-request-icon request-doc-icon">
                          <BsFillCloudArrowUpFill />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-600">
                        <p>Requested</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : row.original.document_request_status.toLowerCase() ===
                  "uploaded" ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <span className="request-doc-upload-icon request-doc-icon">
                          <BsFillCloudArrowUpFill />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-600">
                        <p>Uploaded</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : row.original.document_request_status.toLowerCase() ===
                  "approved" ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <span className="request-doc-approved-icon request-doc-icon">
                          <TbCircleCheck />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-600">
                        <p>Approved</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : row.original.document_request_status.toLowerCase() ===
                  "rejected" ? (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <span className="request-doc-rejected-icon request-doc-icon">
                          <TiDeleteOutline />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="bg-gray-600">
                        <p>Rejected</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : null
              ) : null}
            </span>
            {document_request_name}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    // {
    //   accessorKey: "document_status",
    //   displayName: "Status",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //       className="p-0"
    //     >
    //       Status
    //       <CaretSortIcon className="ml-2 h-4 w-4" />
    //     </Button>
    //   ),
    //   cell: ({ row }) => (
    //     <div className="text-left">
    //       {row?.getValue("document_status")?.toLowerCase() === "active"
    //         ? "Active"
    //         : "Inactive"}
    //     </div>
    //   ),
    //   filterFn: (row, id, value) => {
    //     return value.includes(row.getValue(id));
    //   },
    // },
    {
      accessorKey: "doc_category",
      displayName: "category",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Category
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("doc_category")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_subtype",
      displayName: "Sub Category",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Sub Category
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("document_subtype")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "created_at",
      displayName: "Created At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Created At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("created_at")}</div>
      ),
    },
    {
      accessorKey: "updated_at",
      displayName: "Modified At",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Modified At
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("updated_at")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      displayName: "Actions",
      enableHiding: false,
      header: ({ column }) => {
        if (!shouldShowActionColumn) {
          return null;
        }
        return (
          <Button variant="ghost" className="p-0 !cursor-default">
            Actions
          </Button>
        );
      },
      cell: ({ row }) => {
        if (!shouldShowActionColumn) {
          return null;
        }
        return (
          <div className="flex gap-2">
            {canEdit && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <EditDocumentPopup
                      setDocumentUpdatedToTrue={setDocumentAddedToTrue}
                      tableValues={row.original}
                      //categoryOptions={categoryOptions}
                      subCategoryOptions={subCategoryOptions}
                    />
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-600">
                    <p>Edit Document</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {canDelete && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <DeleteDocumentPopup
                      data={row.original}
                      setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                    />
                  </TooltipTrigger>
                  <TooltipContent className="bg-gray-600">
                    <p>Delete Document</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        );
      },
    },
  ];

  const documentResponseFunc = async () => {
    setLoading(true);
    try {
      const offset = currentPage - 1;
      const filterParams = {};

      columnFilters.forEach((filterItem) => {
        filterParams[filterItem.id] = filterItem.value;
      });

      const sortParam = sorting
        .map((sortItem) => `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`)
        .join(",");

      const params = {
        isAdmin: 1,
        userId: 1,
        limit: pageSize,
        offset: offset,
        ...filterParams,
        global_search: debouncedGlobalFilter,
        sort: sortParam,
      };

      const queryString = new URLSearchParams(params).toString();
      const url = `${baseUrl}/api/get-all-doc-data?${queryString}`;
      const response = await documentData({ token, api: url });

      setTotalPages(Math.ceil(response?.data[0]?.alldocumentcount / pageSize));
      setAllFavourite(response?.data[0]?.allStarValue === "1");

      setDocumentResponse(response?.data[0]?.document);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  // const getClientList = async () => {
  //   const url = `${baseUrl}/api/get-all-client-data?isAdmin=${is_admin}&userId=${id}`;
  //   const res = await clientsData({ token, api: url });
  //   setClientNames(res.data[0].clients);
  // };

  // const fetchCategory = async () => {
  // 	try {
  // 		const response = await getAllFieldTypeList({
  // 			token,
  // 			type: "document_type",
  // 		});
  // 		setCategoryOptions(response.data);
  // 	} catch (error) {
  // 		return error;
  // 	}
  // };

  const fetchSubCategory = async () => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOptions(response.data);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    setSelectAllChecked(false);
    documentResponseFunc();
  }, [
    documentAdded,
    documentDeleted,
    favouriteDocuments,
    bulkFavouriteDocument,
    bulkDeleteDocument,
    currentPage,
    columnFilters,
    debouncedGlobalFilter,
    pageSize,
    sorting,
  ]);

  useEffect(() => {
    console.log("permissions" + permissions);
    // fetchCategory();
    fetchSubCategory();
    // getClientList();
  }, []);

  const setDocumentAddedToTrue = () => {
    setDocumentAdded((count) => count + 1);
  };

  const setDocumentDeletedToTrue = (documentList) => {
    setDocumentDeleted((count) => count + 1);
  };

  const handleFavourites = async (row) => {
    const { document_id, favourite } = row;
    if (favourite !== "1") {
      await markDocumentAsFavourite({ token, ids: document_id });
      setFavouriteDocuments((prev) => (prev === false ? true : false));
    } else {
      await removeDocumentFromFavourite({ token, ids: document_id });
      setFavouriteDocuments((prev) => (prev === true ? false : true));
    }
  };

  const handleBulkAction = async (actionType) => {
    const selectedRowsData = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    //console.log(selectedRowsData);
    const ids = selectedRowsData.map((row) => row.document_id).join(",");
    if (actionType === "delete") {
      setBulkDeleteDocIds(ids);
      setBulkDeleteDocument(true);
    } else {
      setBulkFavouriteDocument(true);
      await markDocumentsAsFavourite({ token, ids });
    }
    setBulkFavouriteDocument((prev) => !prev);
  };

  const table = useReactTable({
    data: documentResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
      globalFilter,
    },

    // globalFilterFn: (rows, columnFilters) => {
    // 	const [globalFilter] = columnFilters.filter(
    // 		(filter) => filter.id === "global"
    // 	);
    // 	if (!globalFilter || !globalFilter.value) return rows;

    // 	return rows.filter((row) => {
    // 		const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
    // 		return name.includes(globalFilter.value.toLowerCase());
    // 	});
    // },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <>
      {bulkDeleteDocument ? (
        <BulkDeletePopup
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
          Docbulkid={bulkDeleteDocIds}
          bulkDeleteDocument={bulkDeleteDocument}
          setBulkDeleteDocument={setBulkDeleteDocument}
          setRowSelection={setRowSelection}
        />
      ) : null}
      <div className="document-table">
        {showAddDocumentForm ? (
          <AddDocument
            onAddDocumentButtonClick={onAddDocumentButtonClick}
            setDocumentAddedToTrue={setDocumentAddedToTrue}
            //categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
          />
        ) : (
          <div className="documents-content">
            <div className="document__table mt-3">
              <div className="w-full bg-white px-2">
                <div className="flex justify-between items-center py-4 flex-wrap gap-4">
                  <Input
                    placeholder="Search Document Name..."
                    value={globalFilter}
                    onChange={(event) =>
                      table.setGlobalFilter(event.target.value)
                    }
                    className="max-w-sm"
                  />
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      className=""
                      onClick={() => setShowFilterButton(!showFilterButton)}
                    >
                      <MixerHorizontalIcon className="mr-1" />
                      Filter
                    </Button>

                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto">
                          Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        {table
                          .getAllColumns()
                          .filter((column) => column.getCanHide())
                          .map((column) => {
                            return (
                              <DropdownMenuCheckboxItem
                                key={column.id}
                                className="capitalize"
                                checked={column.getIsVisible()}
                                onCheckedChange={(value) =>
                                  column.toggleVisibility(!!value)
                                }
                              >
                                {column.columnDef.displayName}
                              </DropdownMenuCheckboxItem>
                            );
                          })}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
                {showFilterButton && (
                  <DataToolbar
                    table={table}
                    apiUrl="/api/get-document-filter-name"
                  />
                )}
                <div className="rounded-md border">
                  {loading ? (
                    <TableSkeleton />
                  ) : (
                    <Table>
                      <TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
                        {table.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                              return (
                                <TableHead key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </TableHead>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableHeader>
                      <TableBody>
                        {table.getRowModel().rows?.length > 0 ? (
                          table.getRowModel().rows.map((row) => (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && "selected"}
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={columns.length}
                              className="h-24 text-center"
                            >
                              {loading ? <TableSkeleton /> : "no records found"}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </div>
                <div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
                  <div className="flex items-center gap-5">
                    <div className="flex-1 text-sm text-muted-foreground">
                      {table.getFilteredSelectedRowModel().rows.length} of{" "}
                      {table.getFilteredRowModel().rows.length} row(s) selected.
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm font-medium">Rows per page</p>
                      <Select
                        value={`${table.getState().pageSize}`}
                        onValueChange={(value) => {
                          setPageSize(Number(value));
                          setCurrentPage(1);
                        }}
                      >
                        <SelectTrigger className="w-auto">
                          <SelectValue
                            placeholder={table.getState().pageSize}
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                              <SelectItem key={pageSize} value={`${pageSize}`}>
                                {pageSize}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div className="space-x-2">
                    <PaginationSection
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentTable;
