import baseUrl from "../config/baseUrl";

const editRole = async ({
  token,
  role_id,
  name,
  permissions,
  status
}) => {

  try {
    let query_string = '';
    if (name !== undefined) {
      query_string += `&name=${name}`;
    }
    if (permissions !== undefined) {
      query_string += `&permissions=${permissions}`;
    }
    const response = await fetch(`${baseUrl}/api/update-roles?role_id=${role_id}&status=${status}${query_string}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editRole;
